.img-container{
    display: flex;
    justify-content:space-evenly;
    margin-top: 20px;
    margin-left: 50px;
}

.img-container>img{
    height: 600px;
}

#buton {
    border-color: black;
    font-size: 20px;
    margin-top: 10px;
}