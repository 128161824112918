.container{
  display:block;
  height: 100vh;
}

.header{
  text-align: center;
  padding-top: 3%;
  align-items: flex-start;
}

.gif_cont{
  display: flex;
  justify-content: center;
  padding-top: 5vh;
}

.gif_cont>img{
  height: 350px;
}

.proceed{
  display: flex;
  justify-content:center;
  padding-top: 100px;
}

#buton {
  border-color: black;
  font-size: 30px;
}


